// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.



//import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'sidebar';
import 'packs/app.min'
import "bootstrap.min.js"
import "custom"
import "parsleyjs"
import 'select2';
import 'select2/dist/css/select2.css';
import "../stylesheets/application"

require('bootstrap')
require('bootstrap-datepicker')
require("packs/bootstrap-datetimepicker.min")
require('jquery.blockUI.min.js')

window.jQuery = $;
window.$ = $;

//Rails.start()
//Turbolinks.start()
ActiveStorage.start()
$(document).on('turbo:load', function() {
  $('[data-parsley-validate]').each(function() {
    new Parsley.Factory(this)
  });
	$(".alert-header-msg").fadeOut(8000);
});
